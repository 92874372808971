import React, { HTMLAttributes } from "react";

import * as styles from "./heading.module.css";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level: '1' | '2' | '3';
  children: React.ReactNode;
  presentational?: boolean;
}

export default function Heading({ level, children, presentational, ...props }: HeadingProps) {
  const Tag = presentational ? 'p' : `h${level}` as const;
  return <Tag {...props} className={`${styles[`heading${level}`]}`}>{children}</Tag>;
}
