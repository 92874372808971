import React from 'react'
import * as styles from "./grid.module.css";

export interface GridProps {
  children: React.ReactNode;
  columns: '2';
}

export default function Grid({ children, columns }: GridProps) {
  return <div className={`${styles.grid} ${styles[`cols${columns}`] ?? ""}`}>{children}</div>
}
