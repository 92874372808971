import React, { AnchorHTMLAttributes } from "react";
import cn from "classnames";
import { Link as GatsbyLink } from "gatsby"

import * as styles from "./link.module.css";

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
}

export const Link = ({ children, href, target, className, ...props }: LinkProps) => {
  const isExternal = href?.startsWith('http');

  if (!isExternal && href) {
    return <GatsbyLink to={href} className={cn(styles.link, className)} {...props}>{children}</GatsbyLink>;
  }

  return (
    <a className={cn(styles.link, className)} href={href} target={!target && isExternal ? "_blank" : target} {...props}>
      {children}
    </a>
  );
};
