import React, { HTMLAttributes } from 'react'

import * as styles from "./address.module.css";

export function Address({ children }: HTMLAttributes<HTMLElement>) {
  return (
    <address className={styles.address}>
      {children}
    </address>
  )
}
