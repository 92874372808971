import { Link } from "gatsby"
import React, { useEffect } from "react"
import * as styles from "./navigation.module.css"

interface NavItem {
  label: string
  href: string
  disabled?: boolean
}

export default function Navigation() {
  const navItems: NavItem[] = [
    { label: "Home", href: "/" },
    { label: "Events", href: "/events" },
    { label: "Travel", href: "/travel" },
    { label: "COVID", href: "/covid" },
    { label: "Registry", href: "/registry" },
    { label: "FAQ", href: "/faq" },
    { label: "RSVP", href: "/rsvp" },
  ]

  useEffect(function scrollToActiveLink() {
    const activeLink = document.querySelector(`.${styles.navItemLinkCurrent.replace(/ /, '.')}`)
    if (activeLink && activeLink.parentNode) {
      (activeLink.parentNode as HTMLLIElement).scrollIntoView(false)
    }
  }, [])

  return (
    <nav className={styles.nav}>
      <ul className={styles.navItems}>
        {navItems
          .filter((_) => !_.disabled)
          .map((item) => (
            <li key={item.href + item.label} className={styles.navItem}>
              <Link
                className={styles.navItemLink}
                activeClassName={styles.navItemLinkCurrent}
                partiallyActive={item.href !== "/"}
                to={item.href}
              >
                {item.label}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  )
}
