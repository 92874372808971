import classNames from "classnames";
import React, { CSSProperties } from "react";
import * as styles from "./columns.module.css";

interface ColumnsProps {
  children: React.ReactNode;
  count?: 2 | 3;
  style?: CSSProperties;
}

export const Columns = ({ children, count = 2, style }: ColumnsProps) => {
  return <div className={classNames(styles.columns, {
    [styles.twoColumns]: count === 2,
    [styles.threeColumns]: count === 3,
  })} style={style}>{children}</div>
}

interface ColumnProps {
  children: React.ReactNode
  hideOnMobile?: boolean
}

export const Column = ({ children, hideOnMobile = false }: ColumnProps) => {
  return (
    <div
      className={classNames(styles.column, {
        [styles.hideOnMobile]: hideOnMobile,
      })}
    >
      {children}
    </div>
  )
}
