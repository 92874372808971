import React, { HTMLAttributes } from "react";
import * as styles from "./paragraph.module.css";

export default function Paragraph({ children, ...props }: HTMLAttributes<HTMLParagraphElement> & { children: React.ReactNode }) {
  return (
    <p {...props} className={styles.paragraph}>
      {children}
    </p>
  );
}
