import React, { ReactNode } from "react"
import * as styles from "./details.module.css"

export const Details = ({ children, ...props }: { children: ReactNode }) => {
  return (
    <details className={styles.details} {...props}>
      {children}
    </details>
  )
}
