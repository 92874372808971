import React, { ReactNode } from "react";
import * as styles from "./List.module.css";

export const OrderedList = ({ children }: { children: ReactNode }) => {
  return <ol className={styles.ol}>{children}</ol>
}

export const UnorderedList = ({ children }: { children: ReactNode }) => {
  return <ul className={styles.ul}>{children}</ul>
}
