import { Link } from "gatsby"
import React from "react"
import * as styles from "./travelCard.module.css";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

interface TravelCardProps {
  title: string
  href: string
  image: IGatsbyImageData
  imageAlt?: string
}

export default function TravelCard({ title, href, image, imageAlt = "" }: TravelCardProps) {
  return (
    <Link to={href} className={styles.card}>
      <GatsbyImage className={styles.picture} image={image} alt={imageAlt} />
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
      </div>
    </Link>
  )
}
