import { StaticQuery, graphql } from "gatsby";
import Layout from "/src/components/Layout";
import TravelCard from "/src/components/TravelCard";
import * as React from 'react';
export default {
  StaticQuery,
  graphql,
  Layout,
  TravelCard,
  React
};