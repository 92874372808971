import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { CSSProperties, ReactNode } from 'react'
import Heading from '../Heading'

import * as styles from './placeCard.module.css'

export interface PlaceCardProps {
  title: string
  href: string
  image: IGatsbyImageData
  imageAlt?: string
  imagePosition?: CSSProperties["objectPosition"];
  openingHours?: string
  mapLink?: string
  children?: ReactNode
}

export const PlaceCard = ({ title, openingHours, href, children, image, imageAlt, imagePosition = "center" }: PlaceCardProps) => {
  return <a href={href} target="_blank" className={styles.placeCard} style={{ background: image.backgroundColor }}>
    {image && <GatsbyImage image={image} alt={imageAlt ?? ""} className={styles.image} objectFit="cover" objectPosition={imagePosition} />}
    <div className={styles.textContainer}>
      <Heading level="3" presentational>{title}</Heading>
      {openingHours && <p className={styles.openingHours}>{openingHours}</p>}
      {children}
    </div>
  </a>
}
