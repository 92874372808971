import React from 'react'
import Helmet from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import { GatsbyImage } from 'gatsby-plugin-image'
import Header from '../Header'
import Navigation from '../Navigation'

import * as styles from './layout.module.css'
import Paragraph from '../Paragraph'
import Heading from '../Heading'
import { Columns, Column } from '../Columns'
import { Address } from '../Address'
import Grid from '../Grid'
import { Link } from '../Link'
import { OrderedList, UnorderedList } from '../List/List'
import { Hr } from '../Hr'
import { Details } from '../Details'

export interface LayoutProps {
  title?: string
  children?: React.ReactNode
}

export const mdxComponents = {
  a: Link,
  p: Paragraph,
  Paragraph,
  h1: (props: any) => <Heading level={1} {...props} />,
  h2: (props: any) => <Heading level={2} {...props} />,
  h3: (props: any) => <Heading level={3} {...props} />,
  Heading,
  Columns,
  Column,
  address: Address,
  Grid,
  ol: OrderedList,
  ul: UnorderedList,
  Img: GatsbyImage,
  hr: Hr,
  details: Details,
}

export default function Layout({ title, children }: LayoutProps) {
  return (
    <MDXProvider components={mdxComponents}>
      <Helmet title={`${title ? `${title} · ` : ''}Victoria & Luke's Wedding`} />
      <Header />
      <Navigation />
      <main className={styles.main}>{children}</main>
    </MDXProvider>
  )
}

