import React from "react"

import * as styles from "./heroContent.module.css"

interface HeroContentProps {
  header: string
  subHeader: string
}

export default function HeroContent({ header, subHeader }: HeroContentProps) {
  return (
    <div className={styles.container}>
      <p className={styles.header}>{header}</p>
      <p className={styles.subHeader}>{subHeader}</p>
    </div>
  )
}
